function Photo() {
  return (
    <img
      className="dummy"
      src="https://i.ytimg.com/vi/8xLaCT2NO9A/hqdefault.jpg"
      alt="Dummy"
    ></img>
  );
}

export default Photo;
