import React from 'react'

function Header() {
  return (
    <div >
      <h1 className='header'>Expense Tracker</h1>
    </div>
  )
}

export default Header